"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var auth_1 = require("@/plugins/auth");
var element_plus_1 = require("element-plus");
var routes = [
    {
        path: "/",
        name: "home",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/Home.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/Home.vue")); });
        },
    },
    {
        path: "/history",
        name: "history",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/History.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/History.vue")); });
        },
    },
    {
        path: "/community",
        name: "Community",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/Community.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/Community.vue")); });
        },
    },
    {
        path: "/create-poem",
        name: "CreatePoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/CreatePoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/CreatePoem.vue")); });
        },
    },
    {
        path: "/modify-poem",
        name: "ModifyPoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/ModifyPoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/ModifyPoem.vue")); });
        },
    },
    {
        path: "/search-poem",
        name: "SearchPoem",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/SearchPoem.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/SearchPoem.vue")); });
        },
    },
    {
        path: "/poem-detail",
        name: "PoemDetail",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/PoemDetail.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/PoemDetail.vue")); });
        },
    },
    {
        path: '/create-poem-by-pic',
        name: 'CreateByPic',
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/CreateByPic.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/CreateByPic.vue")); });
        },
    },
    // 关于界面系列
    {
        path: '/about',
        name: 'About',
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/About.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/About.vue")); });
        },
    },
    //  todo 修改mobile部分
    {
        path: "/about/about_us",
        name: "AboutUs  ",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/AboutUs.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/AboutUs.vue")); });
        },
    },
    {
        path: "/about/feedback",
        name: "Feedback",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/Feedback.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/Feedback.vue")); });
        },
    },
    {
        path: "/about/update_log",
        name: "UpdateLog",
        component: function () {
            return window.innerWidth < 500
                ? Promise.resolve().then(function () { return __importStar(require("../views/mobile/about/UpdateLog.vue")); }) : Promise.resolve().then(function () { return __importStar(require("../views/pc/about/UpdateLog.vue")); });
        },
    }
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes: routes,
});
// 路由级拦截
router.beforeEach(function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
    var username;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!auth_1.AuthList.includes(to.path)) return [3 /*break*/, 1];
                // 白名单，放行
                next();
                return [3 /*break*/, 3];
            case 1: return [4 /*yield*/, (0, auth_1.checkLogin)()];
            case 2:
                username = _a.sent();
                if (username !== null) {
                    // 已登录，放行
                    next();
                }
                else {
                    // 未登录，跳转到统一认证
                    element_plus_1.ElMessage.error({
                        message: "请您登录后再访问此页面",
                    });
                    window.location.href = '/oauth/login';
                    next(false);
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.default = router;
