import axios from "@/plugins/axios.js";
import { ElMessage } from "element-plus";
import {inject} from "vue";
import store from "@/store";
export const getJobResult = async (job_id) => {
  let data = {
    job_id,
  };
  return await axios({
    method: "post",
    url: "jobs/poll-result",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ratePoem = async (job_id, rating, poem_title, content) => {
  let poem_content = '';
  for(let i=0;i<content.length;i++) {
    poem_content = poem_content + content[i];
    if(i%2===0)
      poem_content = poem_content + '，';
    else poem_content = poem_content + '。'
  }
  return await axios({
    method: "post",
    url: "ratings/rate-poems",
    data: JSON.stringify({
      job_id,
      rating,
      poem_title,
      poem_content,
      user_id: "default_user",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getJobPos = async (job_id) => {
  let data = {
    job_id,
  };
  try {
    let response = await axios({
      method: "post",
      url: "jobs/get-job-position",
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    let number = response.data.idx;
    if (!number) number = 0;
    return number;
  } catch (error) {
    console.log(error);
    if (error.response.status === 400)
      ElMessage.error({ message: "job ID格式错误", plain: true });
    else ElMessage.error({ message: "获取任务出错", plain: true });
  }
};

// 分享所创作诗歌
const auth = store.getters.auth;
const isLoggedIn = store.getters.isLoggedIn;
const username = store.getters.username;
export const sharePoems = async (title, content) => {
  const shareTime = new Date().toISOString();
  const userId = "default", userName = "访客", author = "诗千家AI", reviewStatus = "pending";
  let poem_content = '';
  for(let i=0; i<content.length; i++) {
    poem_content = poem_content + content[i];
    if(i % 2 === 0)
      poem_content = poem_content + '，';
    else poem_content = poem_content + '。'
  }
  return axios({
    method: "post",
    url: "community/share-poems", // Assuming the API endpoint is "/api/poem-submission"
    data: JSON.stringify({
      user_id: userId,
      user_name: isLoggedIn ? username : userName,
      share_time: shareTime,
      title: title,
      author: author,
      content: poem_content,
      review_status: reviewStatus
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 浏览社区发帖
export const requestCommunityPosts = async (skip, limit) => {
  return axios({
    method: 'get',
    url: 'community/share-poems/',
    params: {
      skip,
      limit,
    }
  });
};


