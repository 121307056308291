"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeFavorite = exports.fetchFavorites = exports.addFavorite = exports.fetchHistory = exports.convertPoemRecords2Vo = exports.API_HISTORY_REMOVE_FAVORITE = exports.API_HISTORY_GET_FAVORITE = exports.API_HISTORY_ADD_FAVORITE = exports.API_HISTORY_GET_HISTORY = void 0;
var cookie_1 = require("../plugins/cookie");
var common_1 = require("../plugins/common");
/**
 * history: get-history
 * 获取历史记录，需要登录
 */
exports.API_HISTORY_GET_HISTORY = "/history/get-history";
/**
 * history: add-favorite
 * 添加到收藏，需要登录
 */
exports.API_HISTORY_ADD_FAVORITE = "/history/add-favorite";
/**
 * history: get-favorite
 * 获取收藏记录，需要登录
 */
exports.API_HISTORY_GET_FAVORITE = "/history/get-favorites";
/**
 * history: remove-favorite
 * 取消收藏，需要登录
 */
exports.API_HISTORY_REMOVE_FAVORITE = "/history/remove-favorite";
var convertPoemRecords2Vo = function (poems) {
    var vos = [];
    poems.forEach(function (poem) {
        var vo = {
            input_id: poem.input_id,
            userid: poem.userid,
            id: poem.id,
            time: poem.time,
            job_id: poem.job_id,
            title: poem.title,
            dynasty: "当代",
            ifAI: true,
            yan: (0, common_1.poemYanNumber2String)(poem.yan),
            form: poem.form,
            content: (0, common_1.poemContentString2Array)(poem.content),
            is_favorite: poem.is_favorite !== undefined ? poem.is_favorite : false,
        };
        vos.push(vo);
    });
    return vos;
};
exports.convertPoemRecords2Vo = convertPoemRecords2Vo;
/**
 * 获取历史记录
 */
var fetchHistory = function (query) { return __awaiter(void 0, void 0, Promise, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(exports.API_HISTORY_GET_HISTORY, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer ".concat((0, cookie_1.getToken)()), // 假设使用Token验证
                    },
                    body: JSON.stringify(query),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    return [2 /*return*/, { err: true, message: "Failed to fetch history: ".concat(response.statusText) }];
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
exports.fetchHistory = fetchHistory;
/**
 * 添加收藏
 */
var addFavorite = function (job_id) { return __awaiter(void 0, void 0, Promise, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("".concat(exports.API_HISTORY_ADD_FAVORITE, "?job_id=").concat(job_id), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer ".concat((0, cookie_1.getToken)()),
                    },
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    return [2 /*return*/, { err: true, message: "Failed to add favorite: ".concat(response.statusText) }];
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
exports.addFavorite = addFavorite;
/**
 * 获取收藏记录
 */
var fetchFavorites = function (query) { return __awaiter(void 0, void 0, Promise, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(exports.API_HISTORY_GET_FAVORITE, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer ".concat((0, cookie_1.getToken)()),
                    },
                    body: JSON.stringify(query),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    return [2 /*return*/, { err: true, message: "Failed to fetch favorites: ".concat(response.statusText) }];
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
exports.fetchFavorites = fetchFavorites;
/**
 * 取消收藏
 */
var removeFavorite = function (job_id) { return __awaiter(void 0, void 0, Promise, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("".concat(exports.API_HISTORY_REMOVE_FAVORITE, "?job_id=").concat(job_id), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer ".concat((0, cookie_1.getToken)()),
                    },
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    return [2 /*return*/, { err: true, message: "Failed to remove favorite: ".concat(response.statusText) }];
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
exports.removeFavorite = removeFavorite;
