import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "remixicon/fonts/remixicon.css";
import "@/plugins/rem";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhLocale from "element-plus/es/locale/lang/zh-cn";
import {useAuthDirective} from "@/directives/auth";
import { useAuth } from '@/store/auth';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: zhLocale,
});
// 注册element-plus的图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// auth-store 存储鉴权信息
const auth = useAuth();
await auth.initAuth(); // 初始化登录状态
app.provide('auth', auth); // 全局提供登录状态
store.commit('setAuth', auth);
app.directive('auth', useAuthDirective());
app.mount("#app");
